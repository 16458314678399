/* By default DOMPurify should block some tags but there docs aren't really clear about that and in practice some tags weren't blocked by default (title) */
const forbiddenTags = [
  "script",
  "iframe",
  "form",
  "title",
  "applet",
  "base",
  "basefont",
  "command",
  "embed",
  "frame",
  "frameset",
  "keygen",
  "link",
  "meta",
  "noframes",
  "noscript",
  "object",
  "param",
];

const forbiddenAttributes = [
  "accept-charset",
  "accesskey",
  "allow",
  "async",
  "autocapitalize",
  "autofocus",
  "autoplay",
  "buffered",
  "challenge",
  "charset",
  "code",
  "codebase",
  "content",
  "contenteditable",
  "contextmenu",
  "controls",
  "data",
  "decoding",
  "defer",
  "dirname",
  "draggable",
  "dropzone",
  "form",
  "formaction",
  "http-equiv",
  "icon",
  "importance",
  "itemprop",
  "keytype",
  "kind",
  "language",
  "lazyload",
  "manifest",
  "minlength",
  "muted",
  "ping",
  "sandbox",
  "scoped",
  "slot",
  "spellcheck",
  "srcdoc",
  "srclang",
  "start",
  "target",
  "translate",
  "wrap",
];

export { forbiddenAttributes, forbiddenTags };
